import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import G from './G';
const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);
  



  const imageUrls = [
    'https://i.ibb.co/C15dXgB/cropped-photo-2024-05-22-23-29-22.jpg',
    'https://i.ibb.co/vBjzxL9/cropped-photo-2024-05-22-23-29-24.jpg',
    'https://i.ibb.co/jbM63mj/cropped-photo-2024-05-22-23-29-25.jpg',
    'https://i.ibb.co/tQg478h/cropped-photo-2024-05-22-23-29-26.jpg',
    'https://i.ibb.co/3mt3WL2/cropped-photo-2024-05-22-23-29-28.jpg',
    'https://i.ibb.co/mBC9MX5/cropped-photo-2024-05-22-23-29-29.jpg',
    'https://i.ibb.co/Pxg6mvK/cropped-photo-2024-05-22-23-29-30.jpg',
    'https://i.ibb.co/XWCJcC8/cropped-photo-2024-05-22-23-29-31.jpg',
    'https://i.ibb.co/DzL8CRG/cropped-photo-2024-05-22-23-29-33.jpg'
  ];

  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="container">
      <img 
        src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
        alt="Header Image"
        className="header-image"
      />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
      {imageUrls.map(url => (
        <SwiperSlide key={url} className="swiper-slide">
          <img src={url} alt="slide_image"/>
        </SwiperSlide>
      ))}
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

      <div className='textContainer'>
        <img src='https://i.ibb.co/k3FRFbr/cropped-photo-2024-05-22-23-29-28-modified.png' />
        <section>
            <div className='flexRow'>Peach 🍑<div className='buttonStyling'>Online Now</div></div>
            <div className='flexRow'>
              <span>
              Your fav busty blonde next door currently in  {city}, {region}   {country && <img 
                  src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                  alt={`${country} Flag`}
                  style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
                />} 
            
                
              </span>
            </div>


        </section>
      </div>

      <a href="https://onlyfans.com/xxxlilpeach/c4" id="customButton">
      Click here to get to know me
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <p className="exclusiveContent">
      Looking for content partners, DM me on OnlyFans to film w/ me 🎥💓<br/><br/>
      Exclusive content 💖 70% Off for a limited time only! ($3.00)
      </p>
      <Analytics/>
    </div>

    } />
    <Route path="/I-Am-Waiting-For-You/onlyfans.com/xxxlilpeach" element={<G />} />
    </Routes>
    </Router>
  );
}

export default App;
